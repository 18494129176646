
import { defineComponent, onMounted, ref } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import usGridInProbeTop from '@/composable/useGridInProbeTop'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import ProbeSettingForm from '@/components/Form/ProbePage/ProbeSettingForm.vue'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    GridWrapper,
    Grid,
    Dialog,
    ProbeSettingForm
  },
  setup() {
    // Grid 相關：
    const { columns, dataItems, createRandomData } = usGridInProbeTop()
    const bottomDataItems = ref([])

    onMounted(() => {
      dataItems.value = createRandomData(2) as never[]
      bottomDataItems.value = createRandomData(1) as never[]
    })

    // 設定 Prboe
    const { show: showProbeSetting, toggle: toggleProbeSetting } = useToggle()
    // 找不到 Probe
    const { show: showNotFound, toggle: toggleNotFound } = useToggle()
    // 設定成功
    const { show: showSuccess, toggle: toggleSuccess } = useToggle()
    // 設定失敗
    const { show: showFail, toggle: toggleFail } = useToggle()
    // 未知錯誤
    const { show: showUnknown, toggle: toggleUnknown } = useToggle()

    return {
      columns,
      dataItems,
      bottomDataItems,
      showProbeSetting,
      toggleProbeSetting,
      showNotFound,
      toggleNotFound,
      showSuccess,
      toggleSuccess,
      showFail,
      toggleFail,
      showUnknown,
      toggleUnknown
    }
  }
})
