<template>
  <h2 class="build-env-title">建置環境</h2>

  <Tabs>
    <Tab pathName="Site" title="區域列表" />
    <Tab pathName="Network" title="網段列表" />
    <Tab pathName="Probe" title="Probe 列表" />
  </Tabs>

  <div class="main-list-view probe-list-view">
    <!-- 列表容器 -->
    <div class="main-list-container">
      <!-- 上：Probe 容器 -->
      <div class="probe-wrapper">
        <div class="probe-item">
          <img
            class="probe-img"
            :src="require('@/assets/images/img/probe-lg@2x.png')"
            style="width: 152px; height: 123px;"
          />
          <h4 class="probe-title">
            <span class="probe-state is-online"></span>
            P360_2021
          </h4>
          <div class="probe-control">
            <div class="operation-btn" @click="toggleNotFound">
              <SvgIcon icon="change" />
            </div>
            <div class="operation-btn" @click="toggleSuccess">
              <SvgIcon icon="power" />
            </div>
            <div class="operation-btn" @click="toggleFail">
              <SvgIcon icon="garbage" />
            </div>
          </div>
        </div>
        <div class="probe-item">
          <img
            class="probe-img"
            :src="require('@/assets/images/img/probe-md@2x.png')"
            style="width: 93px; height: 90px;"
          />
          <h4 class="probe-title">
            <span class="probe-state is-backup"></span>
            P110_2021
          </h4>
          <div class="probe-control">
            <div class="operation-btn" @click="toggleUnknown">
              <SvgIcon icon="power" />
            </div>
            <div class="operation-btn" @click="toggleNotFound">
              <SvgIcon icon="garbage" />
            </div>
          </div>
        </div>
      </div>
      <!-- 下：表格容器 -->
      <GridWrapper>
        <Grid
          :style="{ height: '172px' }"
          :columns="columns"
          :data-items="dataItems"
          :scrollable="'true'"
          :sortable="false"
          @toggleProbeSetting="toggleProbeSetting"
        ></Grid>
      </GridWrapper>
      <div class="probe-wrapper">
        <div class="probe-item">
          <img
            class="probe-img"
            :src="require('@/assets/images/img/probe-md@2x.png')"
            style="width: 93px; height: 90px;"
          />
          <h4 class="probe-title">
            <span class="probe-state is-backup"></span>
            P110_2020
          </h4>
          <div class="probe-control">
            <div class="operation-btn" @click="toggleNotFound">
              <SvgIcon icon="change" />
            </div>
            <div class="operation-btn" @click="toggleNotFound">
              <SvgIcon icon="power" />
            </div>
            <div class="operation-btn" @click="toggleNotFound">
              <SvgIcon icon="garbage" />
            </div>
          </div>
        </div>
      </div>
      <GridWrapper style="marginBottom: 156px;">
        <Grid
          :style="{ height: '107px' }"
          :columns="columns"
          :data-items="bottomDataItems"
          :scrollable="'true'"
          :sortable="false"
          @toggleProbeSetting="toggleProbeSetting"
        ></Grid>
      </GridWrapper>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <!-- 設定 Probe -->
    <Dialog
      v-if="showProbeSetting"
      :title="'設定 Probe'"
      :width="930"
      :height="400"
      @close="toggleProbeSetting"
    >
      <ProbeSettingForm @close="toggleProbeSetting" />
    </Dialog>
    <!-- 找不到 Probe -->
    <Dialog
      v-if="showNotFound"
      class="alert-dialog probe-not-found"
      :height="400"
      @close="toggleNotFound"
      :title="'notfound'"
    >
      <img
        :src="require('@/assets/images/img/probe-not-found@2x.png')"
        style="width: 208px; height: 157px;"
      />
      <p class="alert-text">找不到 Probe</p>
      <button class="btn form-btn-outline" @click="toggleNotFound">
        返回
      </button>
    </Dialog>
    <!-- 設定成功 -->
    <Dialog
      v-if="showSuccess"
      class="alert-dialog"
      @close="toggleSuccess"
      :title="'success'"
    >
      <SvgIconFixedColor class="alert-icon" icon="alert-success" />
      <p class="alert-text">設定成功</p>
      <button class="btn form-btn-outline" @click="toggleSuccess">
        返回
      </button>
    </Dialog>
    <!-- 設定失敗 -->
    <Dialog
      v-if="showFail"
      class="alert-dialog"
      @close="toggleFail"
      :title="'fail'"
    >
      <SvgIconFixedColor class="alert-icon" icon="alert-fail" />
      <p class="alert-text">設定失敗</p>
      <button class="btn form-btn-outline" @click="toggleFail">
        返回
      </button>
    </Dialog>
    <!-- 未知錯誤 -->
    <Dialog
      v-if="showUnknown"
      class="alert-dialog"
      @close="toggleUnknown"
      :title="'unknown'"
    >
      <SvgIconFixedColor class="alert-icon" icon="alert-unknown" />
      <p class="alert-text">未知錯誤</p>
      <button class="btn form-btn-outline" @click="toggleUnknown">
        返回
      </button>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import usGridInProbeTop from '@/composable/useGridInProbeTop'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import ProbeSettingForm from '@/components/Form/ProbePage/ProbeSettingForm.vue'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    GridWrapper,
    Grid,
    Dialog,
    ProbeSettingForm
  },
  setup() {
    // Grid 相關：
    const { columns, dataItems, createRandomData } = usGridInProbeTop()
    const bottomDataItems = ref([])

    onMounted(() => {
      dataItems.value = createRandomData(2) as never[]
      bottomDataItems.value = createRandomData(1) as never[]
    })

    // 設定 Prboe
    const { show: showProbeSetting, toggle: toggleProbeSetting } = useToggle()
    // 找不到 Probe
    const { show: showNotFound, toggle: toggleNotFound } = useToggle()
    // 設定成功
    const { show: showSuccess, toggle: toggleSuccess } = useToggle()
    // 設定失敗
    const { show: showFail, toggle: toggleFail } = useToggle()
    // 未知錯誤
    const { show: showUnknown, toggle: toggleUnknown } = useToggle()

    return {
      columns,
      dataItems,
      bottomDataItems,
      showProbeSetting,
      toggleProbeSetting,
      showNotFound,
      toggleNotFound,
      showSuccess,
      toggleSuccess,
      showFail,
      toggleFail,
      showUnknown,
      toggleUnknown
    }
  }
})
</script>

<style scoped></style>
