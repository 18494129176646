import { ref, markRaw } from 'vue'

// Cells
import Operation from '../components/Grid/Cell/ProbePage/Operation.vue'
import OperationHeader from '../components/Grid/Cell/SitePage/OperationHeader.vue'
import ProbeIP from '../components/Grid/Cell/SitePage/ProbeIP.vue'
import Status from '../components/Grid/Cell/ProbePage/Status.vue'

// 區域列表中使用 Kendo Grid
const useGridInSite = () => {
  // grid 資料
  const dataItems = ref([])

  // Header 欄位
  const columns = ref([
    {
      field: 'Status',
      title: '狀態',
      width: '92px',
      cell: markRaw(Status)
    },
    {
      field: 'ProbeName',
      title: 'Probe 名稱',
      width: '113px'
    },
    {
      field: 'Operation',
      title: '操作',
      width: '117px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'ProbeIP',
      title: 'Probe IP',
      width: '133px'
    },
    {
      field: 'ProbeIP',
      title: 'Probe IP',
      width: '145px',
      cell: markRaw(ProbeIP)
    },
    {
      field: 'HostIP',
      title: 'Host IP',
      width: '145px',
      cell: markRaw(ProbeIP)
    },
    {
      field: 'SiteName',
      title: '區域',
      width: '145px'
    },
    {
      field: 'ID',
      title: 'ID',
      width: '145px'
    },
    {
      field: 'SwitchNumber',
      title: 'Switch 數量',
      width: '145px'
    },
    {
      field: 'Notes',
      title: '備援 Probe 資訊',
      width: 'auto'
    }
  ])

  // 生成假資料
  const createRandomData = count => {
    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        Status: idx == 0 ? 'online' : 'backup',
        ProbeName: 'ADOJODD23',
        Operation: '',
        ProbeIP: '192.168.111.111',
        HostIP: '192.168.111.111',
        SiteName: 'Taipei Office',
        ID: '3196413296',
        SwitchNumber: 9,
        Notes: ''
      }))
  }

  return {
    columns,
    dataItems,
    createRandomData
  }
}

export default useGridInSite
