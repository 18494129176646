
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList
  },
  setup() {
    const probeName = ref('192.168.66.171')
    // 類型
    const siteValue = ref(0)
    const siteArray = ref([
      { name: 'Taipei Office', value: 0 },
      { name: 'San Jos Office', value: 1 }
    ])
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      probeName,
      siteValue,
      siteArray,
      submitForm
    }
  }
})
