<template>
  <div class="form-wrapper">
    <form>
      <div class="single-feild-group" style="padding-left: 120px;">
        <label class="feild-name">Probe 名稱</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput
            :type="'text'"
            style="width: 280px;"
            v-model="probeName"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group" style="padding-left: 120px;">
        <label class="feild-name">類型</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <DropDownList
            style="width: 280px;"
            v-model="siteValue"
            :data-source="siteArray"
            :data-text-field="'name'"
            :data-value-field="'value'"
          />
        </FeildInputGroup>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成新增
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList
  },
  setup() {
    const probeName = ref('192.168.66.171')
    // 類型
    const siteValue = ref(0)
    const siteArray = ref([
      { name: 'Taipei Office', value: 0 },
      { name: 'San Jos Office', value: 1 }
    ])
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      probeName,
      siteValue,
      siteArray,
      submitForm
    }
  }
})
</script>

<style scoped></style>
